const wrap_cache = require(`nllib/src/promise-helper/wrap_cache`);
const consumer_user_info = require(`../../data-service/haolv-default/consumer_user_info`);

const get_user_info = function (pParameter) {
    if (!pParameter) {
        pParameter = {};
    }

    const clear_cache = pParameter.clear_cache;

    const fn = consumer_user_info;

    return wrap_cache({
        fn: fn,
        clear_cache: clear_cache
    });
};

module.exports = get_user_info;