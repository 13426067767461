const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request/__request_contentType_json`);

// 接口文档地址：
// http://219.128.52.2:18765/doc.html#/haolv-consumer/consumer-dubbo-controller/usernameUsingPOST
const consumer_user_info = (pParameter) => {
  if (!pParameter) pParameter = {};
  const name = pParameter.name || ``;

  const p = {
    method: 'post',
    urlSuffix: '/consumer/user/info',
    data: {
      name,
    },
  };
  return Q.when()
    .then(function () {
      return __request(p);
    })
    .then(function (data) {
      return Q.resolve(data);
    })
    .catch(function (ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    })
    ;
};
module.exports = consumer_user_info;
