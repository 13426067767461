// 审批流更新状态
// http://192.168.0.12:8765/doc.html#/haolv-consumer/t-bd-approve-flow-controller/updateStatusUsingPOST

const __request = require(`./__request/__request_contentType_json`);
const consumer_approveFlow_updateStatus = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/approveFlow/updateStatus',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_approveFlow_updateStatus;