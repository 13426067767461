// 添加或修改审批流
// http://192.168.0.12:8765/doc.html#/haolv-consumer/t-bd-approve-flow-controller/addOrUpdateUsingPOST

const __request = require(`./__request/__request_contentType_json`);
const consumer_approveFlow_addOrUpdate = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/approveFlow/addOrUpdate',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_approveFlow_addOrUpdate;