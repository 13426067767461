import Q from "q";
import moment from "moment";
// 审核流程列表
import consumer_approveFlow_pageList from "@/lib/data-service/haolv-default/consumer_approveFlow_pageList.js";
// 新增或修改审核流
import consumer_approveFlow_addOrUpdate from "@/lib/data-service/haolv-default/consumer_approveFlow_addOrUpdate.js";
// 审核流更改状态
import consumer_approveFlow_updateStatus from "@/lib/data-service/haolv-default/consumer_approveFlow_updateStatus.js";
// 部门列表
import consumer_department_getDepartmentList from "@/lib/data-service/haolv-default/consumer_department_getDepartmentList.js";
// 职位列表
import consumer_web_position_getPositionAll from "@/lib/data-service/haolv-default/consumer_web_position_getPositionAll.js";
// 获取所有公司员工
import consumer_web_staff_staffAll from "@/lib/data-service/haolv-default/consumer_web_staff_staffAll.js";
// 获取所有审核角色
import consumer_role_roleAll from "@/lib/data-service/haolv-default/consumer_role_roleAll.js";
// 审批流详情
import consumer_approveFlow_detail from "@/lib/data-service/haolv-default/consumer_approveFlow_detail.js";
// 部门
import consumer_department_getDepartments from "@/lib/data-service/haolv-default/consumer_department_getDepartments.js";
//查询退改政策
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
import VirtualList from 'vue-virtual-scroll-list';
import get_user_info from "@/lib/common-service/get_user_info";
import consumer_approveFlow_pageWorkFlowList from '@/lib/data-service/haolv-default/consumer_approveFlow_pageWorkFlowList' // 新版审批流
import consumer_approveFlow_workflowUpdateStatus from '@/lib/data-service/haolv-default/consumer_approveFlow_workflowUpdateStatus'; // 新版审批流设置状态
import consumer_workflow from '@/lib/data-service/haolv-default/consumer_workflow';
import {getTemplateGroupVo, getUpdateFormGroupName, applicableScope} from '@/lib/data-service/haolv-default/design'
import {awaitWrap} from "@/page/admin/company-management/staff-management/common/unit";
export default {
  data() {
    var validateRole = (rule, value, callback) => {
      const sourceType = this.approveNode.sourceType;
      if (sourceType == 1) {
        if (value === "" || value === undefined) {
          callback(new Error("请选择审核角色"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validateStaff = (rule, value, callback) => {
      const sourceType = this.approveNode.sourceType;
      if (sourceType == 2) {
        if (value === "" || value === undefined || value === null) {
          callback(new Error("请选择审核角色"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };

    return {
      pageTitle: "添加审批流程",
      loading: false,
      show: true,
      showType: "first",
      dialogVisible: false,
      rangeVisible: false,
      isFold: false,
      // 列表搜索
      form: {
        currentPage: 1,
        pageSize: 10,
        approvalFlowType: "",
        status: "",
        companyId: '',
        approvalFlowName: '', // 审批流名称-new
        groupId: '', // 审批流类型-new
        isStop: '', // 是否已停用-new
      },
      pager: {
        pageSizes: [10, 15, 20, 25, 30],
        total: 0,
      },
      groupIdList: [
          // 审批流类型 1.出差 2.超标 3.报销 4.改签 5.退票 6.授信 7.预定
        {value: 1, label: '出差'},
        {value: 2, label: '超标'},
        {value: 3, label: '报销'},
        {value: 4, label: '改签'},
        {value: 5, label: '退票'},
        {value: 6, label: '授信'},
        {value: 7, label: '预定'},
      ],
      isStopList: [
          // 是否已停用 0.关闭 1.启用
        {value: 1, label: '启用'},
        {value: 0, label: '关闭'},
      ],
      cascaderProp: {
        checkStrictly: true,
        value: "value",
        label: "label",
        children: "children",
        emitPath: false,
      },
      allocation: {
        approvalFlowTypeSelect: [
          { label: "全部", value: "" },
          { label: "出差申请", value: "1" },
          { label: "超标申请", value: "2" },
          { label: "报销申请", value: "3" },
        ],
        status: [
          { label: "全部", value: "" },
          { label: "启用", value: "1" },
          { label: "停用", value: "2" },
        ],

        approvalFlowTypeRadio: [
          { text: "出差申请", label: "1" },
          { text: "超标申请", label: "2" },
          { text: "报销申请", label: "3" },
        ],
        department: [],
        position: [],
        staff: [],
        role: [],
        departmentAll: [],
      },
      list: [],
      // 添加节点
      approveNode: {
        roleId: "",
        staffId: "",
        nodeName: "",
        sourceType: "1",
      },
      approveRules: {
        nodeName: [
          { required: true, message: "请输入节点名称", trigger: "blur" },
        ],
        roleId: [{ validator: validateRole, trigger: "blur" }],
        staffId: [{ validator: validateStaff, trigger: "blur" }],
      },
      // 添加审批流
      flowForm: {
        id: "",
        approvalFlowType: "",
        approvalFlowNo: "",
        approvalFlowName: "",
        deptIdList: [],
        positionIdList: [],
        staffIdList: "",
        approveNodeList: [],
      },
      flowRules: {
        approvalFlowType: [
          { required: true, message: "请选择审批流类型", trigger: "blur" },
        ],
        approvalFlowNo: [
          { required: true, message: "请输入审批流编号", trigger: "blur" },
        ],
        approvalFlowName: [
          { required: true, message: "请输入审批流名称", trigger: "blur" },
        ],
        deptIdList: [
          { required: true, message: "请选择适用部门", trigger: "blur" },
        ],
        positionIdList: [
          { required: true, message: "请选择适用岗位", trigger: "blur" },
        ],
        staffIdList: [
          { required: true, message: "请选择适用员工", trigger: "blur" },
        ],
        approveNodeList: [
          {
            required: true,
            message: "请至少添加一个审核节点",
            trigger: "blur",
          },
        ],
      },
      // 展示适用范围
      usableForm: {
        deptNames: "",
        positionNames: "",
        staffNames: "",
      },
      staffForm: {
        deptId: "",
        staffName: "",
      },
      staffList: [],
      selectStaff: "",
      curId: 0,
      businessRuleControl: 1, // 因公出差规则 1：出差单审批 2：预订审批

      addNodeDialogLoading: false, // 添加节点弹窗的loading
    };
  },
  components: {
    VirtualList
  },
  methods: {
    show_add() {
      if (this.$store.state.workflow === '1') {
        this.$router.push({
          name: 'admin-approval-design',
          query: {}
        })
      } else {
        this.$router.push({
          name: 'admin-approval-process-set',
          query: {
            type: '1',
          }
        })
      }

    },
    // index
    indexMethod(index) {
      return (this.form.currentPage - 1) * 10 + index + 1;
    },
    req_list(params) {
      const _this = this;
      _this.list = [];
      _this.loading = true;
      return Q.when()
        .then(function() {
          return consumer_approveFlow_pageList(params);
        })
        .then(function(res) {
          _this.list = res.datas.list;
          _this.pager.total = res.datas.totalCount;
          _this.loading = false;
          return Q.resolve();
        });
    },
    req_department(params) {
      const _this = this;
      return Q.when()
        .then(function() {
          return consumer_department_getDepartmentList(params);
        })
        .then(function(res) {
          const deptIdList = _this.flowForm.deptIdList;
          let list = res.datas.departmentListResults;
          list.unshift(
              {
                deptName: "全部",
                deptId: "全部",
                disabled: false
              },
              {
                deptName: "不适用",
                deptId: "不适用",
                disabled: false
              },
          );
          const formatDept = (list) => {
            list = list.map((item) => {
              if (item.childrenList) {
                return {
                  value: item.deptId,
                  label: item.deptName,
                  disabled: false,
                  children: formatDept(item.childrenList),
                };
              } else {
                return {
                  value: item.deptId,
                  label: item.deptName,
                  disabled: false,
                };
              }
            });
            return list;
          };
          _this.allocation.department = formatDept(list);
          _this.allocation.departmentAll = JSON.parse(
            JSON.stringify(_this.allocation.department)
          );
          _this.staffForm.deptId = "全部";
          _this.req_search_staff();
          return Q.resolve();
        });
    },
    req_position(params) {
      const _this = this;
      return Q.when()
        .then(function() {
          return consumer_web_position_getPositionAll(params);
        })
        .then(function(res) {
          const list = res.datas.map((item) => {
            item.disabled = true;
            return item;
          });
          list.unshift(
              {
                positionName: "全部",
                id: "全部",
                disabled: false
              },
              {
                positionName: "不适用",
                id: "不适用",
                disabled: true
              },
          );
          _this.allocation.position = list;
          return Q.resolve();
        });
    },
    req_staff(params) {
      const _this = this;
      return Q.when()
        .then(function() {
          return consumer_web_staff_staffAll(params);
        })
        .then(function(res) {
          const list = res.datas.map((item) => {
            item.disabled = true;
            return item;
          });
          list.unshift(
              {
                staffName: "全部",
                staffId: "全部",
                disabled: false
              },
              {
                staffName: "不适用",
                staffId: "不适用",
                disabled: true
              },
          );
          _this.allocation.staff = list;
          return Q.resolve();
        });
    },
    req_role(params) {
      const _this = this;
      return Q.when()
        .then(function() {
          return consumer_role_roleAll({ roleSource: 1 });
        })
        .then(function(res) {
          _this.allocation.role = res.datas;
        });
    },
    req_update(params) {
      const _this = this;
      return Q.when()
        .then(function() {
          return _this.$store.state.workflow === '1' ? getUpdateFormGroupName(params) : consumer_approveFlow_updateStatus(params);
        })
        .then(function(res) {
          _this.$message.success(
            `${
              params.status === 1
                ? "启用"
                : params.status === 2
                ? "停用"
                : params.status === 3
                ? "删除"
                : ""
            }成功`
          );
          if (_this.$store.state.workflow === '1') {
            // 开启新的审批流
            _this.reqNewList(_this.form);
          } else {
            // 用旧的
            _this.req_list(_this.form);
          }
          return Q.resolve();
        });
    },
    // 提交修改和添加
    req_commit(params) {
      const _this = this;
      return Q.when()
        .then(function() {
          return consumer_approveFlow_addOrUpdate(params);
        })
        .then(function(res) {
          _this.$message.success(`${params.id ? "修改成功" : "添加成功"}`);
          _this.cancel_examine();

          if (_this.$store.state.workflow === '1') {
            // 开启新的审批流
            _this.reqNewList(_this.form);
          } else {
            // 用旧的
            _this.req_list(_this.form);
          }
        });
    },
    // 请求之前的设置
    req_detail(params) {
      const _this = this;
      return Q.when()
        .then(function() {
          return consumer_approveFlow_detail(params);
        })
        .then(function(res) {
          const detail = res.datas || {};
          const department = _this.allocation.department;

          _this.flowForm = {
            approvalFlowType: detail ? detail.approvalFlowType.toString() : '',
            approvalFlowNo: detail ? detail.approvalFlowNo : '',
            approvalFlowName: detail ? detail.approvalFlowName : '',
            approveNodeList: detail ? detail.nodeList : '',
            deptIdList: [],
            id: detail ? detail.id : '',
            positionIdList: [],
            staffIdList: [],
          };
          if (detail.deptNames === "不适用") {
            _this.flowForm.deptIdList = ["不适用"];
            _this.allocation.department.forEach((item) => {
              if (item.value !== "不适用") {
                item.disabled = true;
              } else {
                item.disabled = false;
              }
            });
          } else {
            if (detail.deptNames === "全部") {
              _this.flowForm.deptIdList = ["全部"];
              _this.allocation.department.forEach((item) => {
                if (item.value !== "全部") {
                  item.disabled = true;
                }
              });
            } else {
              // 把部门转换成组件需要的格式进去回显
              let format_dept = (list, parentId) => {
                let arr = [];
                list.forEach((item) => {
                  let arr1 = [];

                  if (parentId) arr1 = [...parentId];
                  arr1.push(item.value);
                  if (item.children) {
                    let arr2 = format_dept(item.children, arr1);
                    arr1 = [...arr2];
                    arr = [...arr, ...arr1];
                  } else {
                    arr.push(arr1);
                  }
                });
                return arr;
              };
              let deptIdList = [];
              const deptIds = format_dept(department);
              deptIds.forEach((item) => {
                detail.deptList.forEach((dept) => {
                  if (item.indexOf(dept.id) !== -1) deptIdList.push(item);
                });
              });
              _this.flowForm.deptIdList = deptIdList;
            }
          }

          if (detail.positionNames === "不适用") {
            _this.flowForm.positionIdList = ["不适用"];
            _this.allocation.position.forEach((item) => {
              if (item.positionName !== "不适用") {
                item.disabled = true;
              } else {
                item.disabled = false;
              }
            });
          } else {
            if (detail.positionNames === "全部") {
              _this.flowForm.positionIdList = ["全部"];
              _this.allocation.position.find((item) => {
                return item.positionName !== "全部"
              }).disabled = true;
            } else {
              _this.flowForm.positionIdList = detail.positionList.map(
                  (item) => item.id
              );
              _this.allocation.position.forEach((item) => {
                  item.disabled = false;
              });
            }
          }

          if (detail.staffNames === "不适用") {
            _this.flowForm.staffIdList = ["不适用"];
            _this.allocation.staff.forEach((item) => {
              if (item.staffName !== "不适用") {
                item.disabled = true;
              } else {
                item.disabled = false;
              }
            });
          } else {
            if (detail.staffNames === "全部") {
              _this.flowForm.staffIdList = ["全部"];
              _this.allocation.staff.find((item) => {
                return item.staffName !== "全部"
              }).disabled = true;
            } else {
              _this.flowForm.staffIdList = detail.staffList.map(
                  (item) => item.id
              );
              _this.allocation.staff.forEach((item) => {
                  item.disabled = false;
              });
            }
          }

          _this.show = false;
          return Q.resolve();
        });
    },
    req_search_staff() {
      const _this = this;
      const staffForm = _this.staffForm;
      const params = {
        deptId: staffForm.deptId,
        staffName: staffForm.staffName,
      };
      _this.staffList = [];
      return Q.when()
        .then(function() {
          _this.addNodeDialogLoading = true;
          if (params.deptId === "全部") params.deptId = "";
          return consumer_web_staff_staffAll(params);
        })
        .then(function(res) {
          _this.addNodeDialogLoading = false;
          _this.staffList = res.datas;
          return Q.resolve();
        });
    },
    staff_change(value) {
      this.selectStaff = value;
    },
    handleClose() {
      this.selectStaff = "";
      this.approveNode.staffId = "";
    },
    format_department_all(list) {
      let arr = [];
      list.forEach((item) => {
        item.children = [];
        if (item.parentId) {
          list.forEach((dept) => {
            if (dept.id === item.parentId) {
              dept.children.push(item);
            }
          });
        } else {
          arr.push(item);
        }
      });
      list.forEach((item) => {
        if (!item.children.length) delete item.children;
      });
      arr.unshift({ name: "全部", id: "全部" });
      return arr;
    },
    // 把获取的部门数据转成组件需要的格式
    disDept(val) {
      const list = val.flat(Infinity);
      const departments = this.allocation.department;
      if(list.length && list.includes('不适用')) {
        for (let j = 0; j < departments.length; j++) {
          const department = departments[j];
          if (department.value !== "不适用") department.disabled = true;
        }
        return
      }
      if (!list.length || !list.includes('全部')) {
        departments.forEach((item) => item.disabled = false);
      } else {
        for (let j = 0; j < departments.length; j++) {
          const department = departments[j];
          if (department.value !== "全部") department.disabled = true;
        }
      }
    },
    disPosition(val) {
      const list = val.flat(Infinity);
      const positions = this.allocation.position;
      if (list.length && list.includes('不适用')) {
        for (let j = 0; j < positions.length; j++) {
          const position = positions[j];
          if (position.id !== "不适用") position.disabled = true;
        }
        return
      }
      if (!list.length || !list.includes('全部')) {
        positions.forEach((item) => item.disabled = false);
      } else {
        for (let j = 0; j < positions.length; j++) {
          const position = positions[j];
          if (position.id !== "全部") position.disabled = true;
        }
      }
    },
    disStaff(val) {
      const list = val.flat(Infinity);
      const staffs = this.allocation.staff;
      if (list.length && list.includes('不适用')) {
        for (let j = 0; j < staffs.length; j++) {
          const staff = staffs[j];
          if (staff.staffId !== "不适用") staff.disabled = true;
        }
        return
      }
      if (!list.length || !list.includes('全部')) {
        staffs.forEach((item) => item.disabled = false);
      } else {
        for (let j = 0; j < staffs.length; j++) {
          const staff = staffs[j];
          if (staff.staffId !== "全部") staff.disabled = true;
        }
      }
    },
    search() {
      this.form.currentPage = 1;
      if (this.$store.state.workflow === '1') {
        // 开启新的审批流
        this.reqNewList(this.form);
      } else {
        // 用旧的
        this.req_list(this.form);
      }
    },
    change_status(val, status) {
      const message =
        status === 1
          ? "启用"
          : status === 2
          ? "停用"
          : status === 3
          ? "删除"
          : "";
      this.$confirm(`确认${message}此审批流?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {

          this.req_update({ templateId: val.approvalFlowNo, id: val.id, status });
        })
        .catch(() => {});
    },
    change_currentPage(currentPage) {
      this.form.currentPage = currentPage;
      // this.req_list(this.form);
      if (this.$store.state.workflow === '1') {
        // 开启新的审批流
        this.reqNewList(this.form);
      } else {
        // 用旧的
        this.req_list(this.form);
      }
    },
    change_pageSize(pageSize) {
      this.form.pageSize = pageSize;
      this.form.currentPage = 1;
      // this.req_list(this.form);
      if (this.$store.state.workflow === 1) {
        // 开启新的审批流
        this.reqNewList(this.form);
      } else {
        // 用旧的
        this.req_list(this.form);
      }
    },
    add_examine_node() {
      this.dialogVisible = true;
    },
    before_close() {
      this.dialogVisible = false;
      this.approveNode = {
        roleId: "",
        staffList: [],
        nodeName: "",
        sourceType: "1",
      };
      this.$refs.approveNode.clearValidate();
    },
    save_node() {
      this.$refs.approveNode.validate((valid) => {
        if (valid) {
          let approveNodeList = this.flowForm.approveNodeList;
          const approveNode = this.approveNode;
          const pageTitle = this.pageTitle;
          let obj = {};
          obj.nodeName = approveNode.nodeName;
          obj.sourceType = approveNode.sourceType;
          if (pageTitle === "添加审批流程") {
            // obj.linkNum = approveNodeList.length + 1;
          } else if (pageTitle === "编辑审批流程") {
          }
          // 根据选择的类型创建节点
          if (approveNode.sourceType == 1) {
            obj.relevanceId = approveNode.roleId;
          } else if (approveNode.sourceType == 2) {
            obj.relevanceId = approveNode.staffId;
          }
          approveNodeList.push(obj);
          this.before_close();
        } else {
          return false;
        }
      });
    },
    delete_examine_node(index) {
      this.flowForm.approveNodeList.splice(index, 1);
    },
    commit_examine() {

      this.$refs.flowForm.validate((valid) => {
        if (valid) {
          const flowForm = this.flowForm;
          const departmentAll = this.allocation.department;
          const positionIdList = flowForm.positionIdList.flat(Infinity);
          const staffIdList = flowForm.staffIdList.flat(Infinity);

          const allocation = this.allocation;
          const position = allocation.position;
          const staff = allocation.staff;

          let params = {
            id: flowForm.id,
            approvalFlowType: flowForm.approvalFlowType,
            approvalFlowNo: flowForm.approvalFlowNo,
            approvalFlowName: flowForm.approvalFlowName,
            approveNodeList: flowForm.approveNodeList,
            deptNames: "",
            deptIdList: [],
            positionNames: "",
            positionIdList: [],
            staffNames: "",
            staffIdList: [],
          };

          // 把选择的部门格式化成一维数组
          const deptNode = this.$refs.dept.getCheckedNodes();
          let deptIdList = deptNode.map((item) => item.value);
          if (deptIdList.indexOf("不适用") > -1) {
            params.deptIdList = [];
            params.deptNames = '不适用'
          } else {
            if (deptIdList.indexOf("全部") === -1) {
              params.deptNames = deptNode.map((item) => item.label).join(",");
              params.deptIdList = deptIdList;
            } else {
              params.deptNames = "全部";
              const format = (list) => {
                let arr = [];
                list.forEach((item) => {
                  if (item.children) {
                    arr = [...arr, item.value, ...format(item.children)];
                  } else {
                    if (item.value !== "全部" && item.value !== "不适用") arr.push(item.value);
                  }
                });
                return arr;
              };
              params.deptIdList = format(departmentAll);
            }
          }

          const isPositionAll = positionIdList.includes('全部');
          const selectPosition = isPositionAll ? position : position.filter((item) => positionIdList.includes(item.id));
          if (positionIdList.indexOf("不适用") > -1) {
            params.positionIdList = [];
            params.positionNames = "不适用"
          } else {
            selectPosition.forEach((item) => {
              if (item.id !== '全部' && item.id !== "不适用") {
                params.positionIdList.push(item.id);
                if (!isPositionAll) params.positionNames += `${item.positionName},`;
              }
            });
            if (isPositionAll) params.positionNames = "全部";
          }

          const isStaffAll = staffIdList.includes('全部');
          const selectStaff = isStaffAll ? staff : staff.filter((item) => staffIdList.includes(item.staffId));
          if (staffIdList.indexOf("不适用") > -1) {
            params.staffIdList = [];
            params.staffNames = "不适用"
          } else {
            selectStaff.forEach((item) => {
              if (item.staffId !== '全部' && item.staffId !== "不适用") {
                params.staffIdList.push(item.staffId);
                if (!isStaffAll) params.staffNames += `${item.staffName},`;
              }
            });
            if (isStaffAll) params.staffNames = "全部";
          }

          this.req_commit(params);
        } else {
          return false;
        }
      });
    },
    format_department(list) {
      list.forEach((item) => {
        item.disabled = false;
        if (item.children) this.format_department(item.children);
      });
    },
    cancel_examine() {
      this.show = true;
      this.flowForm = {
        id: "",
        approvalFlowType: "",
        approvalFlowNo: "",
        approvalFlowName: "",
        deptIdList: [],
        positionIdList: [],
        staffIdList: "",
        approveNodeList: [],
      };
      this.format_department(this.allocation.department);
    },
    change_approval(val) {
      let id = val.id || val.approvalFlowNo;
      if (this.$store.state.workflow === '1') {
        // 新
        this.$router.push({
          name: 'admin-approval-design',
          query: {
            code: id,
          }
        })
      } else {
        //旧
        this.$router.push({
          name: 'admin-approval-process-set',
          query: {
            'curId': id,
            'type': '2',
          }
        })
      }

      //this.pageTitle = "编辑审批流程";
      //this.curId = id;
      //this.req_detail({ id });
    },
    range_close() {
      this.rangeVisible = false;
      this.usableForm = {
        deptNames: "",
        positionNames: "",
        staffNames: "",
      };
    },
    async popup_usable(row) {
      if (this.$store.state.workflow === '1') {
        // 新
        let [err, res] = await awaitWrap(applicableScope({
          approvalFlowNo: row.approvalFlowNo,
        }));
        if (err) {
          return;
        }
        this.usableForm = res.data;
      } else {
        // 旧
        this.usableForm = {
          deptNames: row.deptNames,
          positionNames: row.positionNames,
          staffNames: row.staffNames,
        };
      }

      this.rangeVisible = true;
    },
    chang_sourceType(val) {
      const allocation = this.allocation;
      const approveNode = this.approveNode;
      if (val === "1") {
        allocation.role.forEach((item) => {
          if (item.id === approveNode.roleId)
            approveNode.nodeName = item.roleName;
        });
      } else if (val === "2") {
        approveNode.nodeName = this.selectStaff;
      }
    },
    async refundChangePolicyGet() {
      let res = await refund_change_policy_get();
      this.businessRuleControl = res.datas.businessRuleControl;
      let approvalFlowType = this.allocation.approvalFlowTypeSelect.find(item => {
        return item.value === '7'
      })
      if (this.businessRuleControl === 2 && !approvalFlowType) {
        this.allocation.approvalFlowTypeSelect.push({label: "预订申请", value: "7"})
        this.allocation.approvalFlowTypeRadio.push({text: "预订申请", label: "7"})
      }
    },
    formatterGmtModified(val) {
      if (val.gmtModified) {
          return moment(val.gmtModified).format('YYYY-MM-DD HH:mm:ss')
      } else {
        return '';
      }
    },
    async reqNewList(val) {
      const _this = this;
      _this.list = [];
      _this.loading = true;
      let [err, res] = await awaitWrap(getTemplateGroupVo(val));
      if (err) {
        return;
      }
      _this.list = res.result.list;
      _this.pager.total = res.result.totalCount;
      _this.loading = false;
    },
    async getWorkflow() {
      let [err, res] = await awaitWrap(consumer_workflow());
      if (err) {
        return {
          datas: false,
        };
      }
      return res;
    }
  },
  mounted() {
    /*console.log('4444444');
    let dfd = {};
    dfd.promise = new Promise((resolve,reject)=>{
      dfd.resolve = resolve;
      dfd.reject = reject;
    });


    setTimeout(()=>{
      console.log('222');
      dfd.resolve();
    }, 3000);

    dfd.promise.then(()=>{
      console.log('3333');
    })*/
  },
  async activated() {
    let [err, res] = await awaitWrap(this.getWorkflow());
    /*if (err) {
      return;
    }*/
    let workflow = res.datas ? '1' : '2';
    console.log('w', workflow);
    this.$store.commit('setWorkflow', workflow);
    let [uErr, uRes] = await awaitWrap(get_user_info());
    let userInfo = uRes.datas;
    this.$store.commit('setUserInfo', userInfo);
    console.log(this.$store.state);
    this.form.companyId = this.$store.state.userInfo.companyId;
    if (this.$store.state.workflow === '1') {
      // 开启新的审批流
      await this.reqNewList(this.form);
    } else {
      // 用旧的
      await this.req_list(this.form);
    }

    //await this.req_department();
    //await this.req_position();
    //await this.req_staff();
    //await this.req_role();
    //await this.refundChangePolicyGet();
    /*if (!this.show) {
      await this.req_detail({id: this.curId});
    }*/
  },
  deactivated() {},
};
